<template>
    <div>
        <div class="top-banner"></div>
        <div class="index-register-reason">
            <div class="main">
                <div class="index-title">为什么需要进行版权登记</div>
                <div class="index-subtitle">保障权利归属，维权有利证明</div>
                <div class="copyright-reason">
                    <div class="reason-item">
                        <i class="reason-1"></i>
                        <p class="reason-title">保护</p>
                        <p class="reason-subtitle">登记一次，终生保护，作品被盗用时，维权有据</p>
                    </div>
                    <div class="reason-item">
                        <i class="reason-2"></i>
                        <p class="reason-title">收益</p>
                        <p class="reason-subtitle">登记版权使作品增值，版权转让和授权，都能帮你赚钱</p>
                    </div>
                    <div class="reason-item">
                        <i class="reason-3"></i>
                        <p class="reason-title">福利</p>
                        <p class="reason-subtitle">获得国家认定的版权可享受税收减免优惠</p>
                    </div>
                    <div class="reason-item">
                        <i class="reason-4"></i>
                        <p class="reason-title">荣耀</p>
                        <p class="reason-subtitle">享有国家权威证书<br>《作品登记证书》</p>
                    </div>
                    <div class="reason-item">
                        <i class="reason-5"></i>
                        <p class="reason-title">宣传</p>
                        <p class="reason-subtitle">通过版权机构的定期公告<br>可向社会宣传自己的作品</p>
                    </div>
                </div>
                <div class="btn">立即登记</div>
            </div>

        </div>
        <div class="index-works-register">
            <div class="main">
                <div class="index-title">哪些作品需要进行登记</div>
                <div class="index-subtitle">保护原创作品版权，提升作品交易价值</div>
                <div class="reason-content">
                    <div class="reason-content-left">
                        <i class="icon-register-reason"></i>
                        <div class="btn">立即资讯</div>
                    </div>
                    <div class="reason-content-right">
                        <div class="reason-item">
                            <p>公开作品</p>
                            <span>公开发表在互联网上的作品，及时登记防侵权</span>
                        </div>
                        <div class="reason-item">
                            <p>委托作品</p>
                            <span>先登记后交付，防止甲方不付钱</span>
                        </div>
                        <div class="reason-item">
                            <p>投标作品</p>
                            <span>投标方案，投标作品，防止设计概念被剽窃</span>
                        </div>
                        <div class="reason-item">
                            <p>大赛作品</p>
                            <span>参赛作品，作品价值高、水准高</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                keyword: ''
            }
        },
    };
</script>

<style lang="less" scoped>
    .top-banner {
        width: 100%;
        height: 460px;
        position: relative;
        background: #EEE url(https://res1.justeasy.cn/images/copyright/banner-index.jpg) center no-repeat;
        background-size: cover;
    }

    .index-register-reason {
        width: 100%;
        box-sizing: border-box;
        padding: 43px 0 48px;
        background: #EEF8FA;
        height: 565px;

        .copyright-reason {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;
            margin-bottom: 58px;

            .reason-item {
                width: 224px;
                height: 260px;
                background: #FFF;
                box-sizing: border-box;
                padding: 40px 20px 0;

                i {
                    display: block;
                    width: 100px;
                    height: 100px;
                    margin: 0 auto 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                .reason-1 {
                    background-image: url(https://res1.justeasy.cn/images/copyright/icon-protect.png);
                }

                .reason-2 {
                    background-image: url(https://res1.justeasy.cn/images/copyright/icon-profit.png);
                }

                .reason-3 {
                    background-image: url(https://res1.justeasy.cn/images/copyright/icon-welfare.png);
                }

                .reason-4 {
                    background-image: url(https://res1.justeasy.cn/images/copyright/icon-glory.png);
                }

                .reason-5 {
                    background-image: url(https://res1.justeasy.cn/images/copyright/icon-propaganda.png);
                }

                .reason-title {
                    display: block;
                    width: 100%;
                    text-align: center;
                    height: 20px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #212121;
                    line-height: 20px;
                    margin-bottom: 15px;
                }

                .reason-subtitle {
                    width: 100%;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #757575;
                    line-height: 24px;
                }
            }
        }
    }

    .index-works-register {
        width: 100%;
        box-sizing: border-box;
        padding: 43px 0 93px;
        background: #fff;

        .reason-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 52px;

            .reason-content-left {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-right: 140px;
                height: 356px;

                .icon-register-reason {
                    display: block;
                    width: 552px;
                    height: 237px;
                    background: url(https://res1.justeasy.cn/images/copyright/icon-register.png) center no-repeat;
                    background-size: contain;
                    margin-bottom: 66px;
                }
            }

            .reason-content-right {
                .reason-item {
                    display: block;
                    margin-bottom: 40px;

                    p {
                        display: block;
                        height: 20px;
                        font-size: 18px;
                        font-weight: 400;
                        color: #212121;
                        line-height: 20px;
                        margin-bottom: 15px;
                    }

                    span {
                        height: 16px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #757575;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    .btn {
        width: 160px;
        height: 48px;
        border-radius: 24px;
        font-size: 18px;
        font-weight: 400;
        margin: 0 auto;
        transition: all .3s;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        cursor: pointer;
        background: #00BBD4;
        color: #FEFEFE;
    }

    .main {
        width: 1200px;
        margin: 0 auto;

        .index-title {
            display: block;
            width: 100%;
            text-align: center;
            height: 30px;
            font-size: 28px;
            font-weight: 400;
            color: #212121;
            line-height: 30px;
            margin-bottom: 20px;
        }

        .index-subtitle {
            display: block;
            width: 100%;
            text-align: center;
            height: 18px;
            font-size: 16px;
            font-weight: 400;
            color: #757575;
            line-height: 18px;
        }
    }

    // @media only screen and (max-width: 1000px) {
    //     .top-banner {
    //         height: 220px;
    //         background-position: -80px center;
    //         background-size: auto;
    //         background-size: 760px 220px;
    //     }

    //     .main {
    //         width: 100%;
    //         padding: 0 10px;
    //     }

    //     .index-register-reason {
    //         height: auto;

    //         .copyright-reason {
    //             flex-wrap: wrap;

    //             .reason-item {
    //                 width: calc(50% - 5px);
    //                 height: auto;
    //                 margin-bottom: 10px;
    //                 padding-bottom: 10px;
    //             }
    //         }
    //     }

    //     .index-works-register .reason-content {
    //         flex-wrap: wrap;

    //         .reason-content-left {
    //             margin-right: 0;
    //             width: 100%;
    //             margin-bottom: 20px;

    //             .icon-register-reason {
    //                 width: 100%;
    //             }
    //         }
    //     }
    // }
</style>